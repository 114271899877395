import { Button, Card, Chip, IconButton, Slider, Switch } from '@mui/material';
import { ViewTitle } from 'components/ViewTitle';
import {React, useState} from 'react';
import CNCcontrol from '../../assets/img/CncControl.png';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import StarIcon from '@mui/icons-material/Star';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export default function CncControl({ title, subtitle }) {
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const [speedM1, setSpeedM1] = useState(0);
  const [speedM2, setSpeedM2] = useState(0);
  const [speedM3, setSpeedM3] = useState(0);
  const [rotationSpeed, setRotationSpeed] = useState(0);
  const [weedingSpeed, setWeedingSpeed] = useState(0);
  const [fertilizerSpreadSpeed, setFertilizerSpreadSpeed] = useState(0);
  const [seedSpreaderSpeed, setSeedSpreaderSpeed] = useState(0);

  // Function to handle slider change and update speedM1 state
  const handleSpeedM1 = (event, newValue) => {
    setSpeedM1(newValue);
    console.log('Speed M1 :', newValue);
  };

  const handleSpeedM2 = (event, newValue) => {
    setSpeedM2(newValue);
    console.log('Speed M2 :', newValue);
  };

  const handleSpeedM3 = (event, newValue) => {
    setSpeedM3(newValue);
    console.log('Speed M3 :', newValue);
  };

  const handleRotationSpeed = (event, newValue) => {
    setRotationSpeed(newValue);
    console.log('RotationSpeed :', newValue);
  };

  const handleWeedingSpeed = (event, newValue) => {
    setWeedingSpeed(newValue);
    console.log('WeedingSpeed :', newValue);
  };
  
  const handleFertilizerSpreadSpeed = (event, newValue) => {
    setFertilizerSpreadSpeed(newValue);
    console.log('Fertilizer Spread Speed:', newValue);
  };

  const handleSeedSpreaderSpeed = (event, newValue) => {
    setSeedSpreaderSpeed(newValue);
    console.log('Seed Spreader Speed:', newValue);
  };
  

  const renderButton = () => (
    <Card>
      <div className="flex justify-center">
        <div>
          <div className="flex">
            <div className="py-20" style={{ marginTop: '100px', marginRight: '20px'}}>
                <IconButton>
                    <KeyboardArrowUpIcon sx={{ fontSize: 40 }} style={{ color: '#C91619'}} />  Forward
                </IconButton>
                <IconButton style={{ marginTop: '50px'}}>
                    <KeyboardArrowDownIcon sx={{ fontSize: 40 }} style={{ color: '#C91619'}} /> Backward
                </IconButton>
            </div>
            <img src={CNCcontrol} width="100%" height="100%" />
            <div className="py-20" style={{ marginTop: '100px', marginLeft: '20px'}}>
                <IconButton>
                    <KeyboardArrowUpIcon sx={{ fontSize: 40 }} style={{ color: '#10B61F'}} />  Up
                </IconButton>
                <IconButton style={{ marginTop: '50px'}}>
                    <KeyboardArrowDownIcon sx={{ fontSize: 40 }}  style={{ color: '#10B61F'}}/> Down
                </IconButton>
            </div>
          </div>
          <div className="flex justify-center py-5 ">
          <IconButton>
            Left <ArrowBackIosIcon sx={{ fontSize: 30 }} color="amber" style={{ marginLeft: '20px'}} /> 
          </IconButton>
          <IconButton>
            <ArrowForwardIosIcon sx={{ fontSize: 30 }} color="amber" style={{ marginLeft: '100px', marginRight: '20px'}}/> Right
          </IconButton>
        </div>
        </div>
      </div>{' '}
    </Card>
  );

  const renderButtonandSlide = () => (
    <Card >
      <div className="flex justify-between items-center mt-4">
        <div className="flex w-full items-center">
          <Slider
            value={speedM1} 
            onChange={handleSpeedM1}
            style={{ width: '40%', marginRight: '16px', marginLeft: '50px', color: '#ff0000' }}
          />
          <h4 style={{ color: '#ff0000' }}>SPEED M1 (mm/s) : {speedM1} %</h4>
        </div>
        <div className="flex items-center">
          <IconButton style={{ backgroundColor: '#f44336', color: '#fff', marginRight: '20px' }}>
            <StopCircleIcon />
          </IconButton>
          <h4 style={{  marginRight: '50px' }}>Stop</h4>
        </div>
        <div className="flex items-center" >
          <IconButton style={{ backgroundColor: '#3f51b5', color: '#fff', marginRight: '20px' }}>
            <StarIcon />
          </IconButton>
          <h4 style={{  marginRight: '20px' }}>Start Point</h4>
        </div>
      </div>
      <div className="flex justify-between items-center mt-4">
        <div className="flex w-full items-center" style={{ marginBottom: '10px',color: '#10B61F' }}>
          <Slider
            value={speedM2} 
            onChange={handleSpeedM2}
            style={{ width: '31%', marginRight: '16px', marginLeft: '50px', color: '#10B61F' }}
          />
          <h4>SPEED M2 (mm/s) : {speedM2} %</h4>
        </div>
      </div>
      <div className="flex justify-between items-center mt-4">
        <div className="flex w-full items-center" style={{ marginBottom: '20px',color: '#FAC832' }}>
          <Slider
            value={speedM3} 
            onChange={handleSpeedM3}
            style={{ width: '31%', marginRight: '16px', marginLeft: '50px', color: '#FAC832' }}
          />
          <h4 >SPEED M3 (mm/s) : {speedM3} %</h4>
        </div>
      </div>
    </Card>
  );

  const renderStatus = () => (
    <div style={{ display: 'flex' }}>
      <div style={{ flex: 1, marginRight: '16px' }}>
        <Card>
          <div className="px-2 py-2" style={{ marginLeft: '20px' }}>
            <div>
                ชุดพรวนดิน  
                <Switch  color="secondary" /> 
                    
            </div>
            <div className="flex w-full items-center">
                <Slider
                    value={rotationSpeed} 
                    onChange={handleRotationSpeed}
                    style={{ width: '40%', marginRight: '16px',color: '#7087FF' }}
                />
                <h4 style={{ marginBottom: '0' }}>Rotation Speed ( RPM ) {rotationSpeed} %</h4>
            </div>
          </div>
          <div className="px-2 py-2" style={{ marginLeft: '20px' }}>
            <div>
                ชุดกำจัดวัชพืช  
                <Switch  color="secondary" /> 
                    
            </div>
            <div className="flex w-full items-center">
                <Slider
                    value={weedingSpeed} 
                    onChange={handleWeedingSpeed}
                    style={{ width: '40%', marginRight: '16px',color: '#7087FF' }}
                />
                <h4 style={{ marginBottom: '0' }}>Rotation Speed ( RPM ) {weedingSpeed} %</h4>
            </div>
          </div>
        </Card>
      </div>
      <div style={{ flex: 1 }}>
        <Card>
        <div className="px-2 py-2" style={{ marginLeft: '20px' }}>
            <div>
                ชุดโรยปุ๋ยเม็ด 
                <Switch  color="secondary" /> 
                    
            </div>
            <div className="flex w-full items-center">
                <Slider
                    value={fertilizerSpreadSpeed} 
                    onChange={handleFertilizerSpreadSpeed}
                    style={{ width: '40%', marginRight: '16px',color: '#7087FF' }}
                />
                <h4 style={{ marginBottom: '0' }}>Rotation Speed ( RPM ) {fertilizerSpreadSpeed} %</h4>
            </div>
          </div>
          <div className="px-2 py-2" style={{ marginLeft: '20px' }}>
            <div>
                ชุดหว่านเมล็ดพันธุ์
                <Switch  color="secondary" /> 
            </div>
            <div className="flex w-full items-center">
                <Slider
                    value={seedSpreaderSpeed} 
                    onChange={handleSeedSpreaderSpeed}
                    style={{ width: '40%', marginRight: '16px',color: '#7087FF' }}
                />
                <h4 style={{ marginBottom: '0' }}>Rotation Speed ( RPM ) {seedSpreaderSpeed} %</h4>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
  

  return (
    <div>
      {renderTitle()}
      <div className="py-2">{renderStatus()}</div>
      <div>{renderButton()}</div>
      <div className="py-1">{renderButtonandSlide()}</div>
    </div>
  );
}
