import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import axios from 'axios';
import { PassportAuth } from '../../contexts/AuthContext';

export function HomeNavbar({ onMobileNavOpen }) {
  const history = useHistory();
  const { handleSignout } = useContext(PassportAuth);
  const handlePushToHome = () => {
    history.push('/');
  };
  const onSignOut = async () => {
    await handleSignout();
    history.push('/login');
  };

  return (
    <>
      <nav
        className="top-0 fixed z-10 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg shadow"
        style={{ background: '#FF6600' }}
      >
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between  font-sans">
            <div className="flex">
              <div
                // onClick={() => {
                //   handlePushToHome();
                // }}
                className="cursor-pointer text-white text-base  leading-relaxed inline-block mr-4 py-2 whitespace-no-wrap uppercase"
              >
                SOBOT Control
              </div>
            </div>
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => onMobileNavOpen()}
            >
              <i className="fas fa-bars"></i>
            </button>
          </div>
        </div>
      </nav>
    </>
  );
}
HomeNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func,
};
export default HomeNavbar;
