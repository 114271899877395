import { combineReducers } from 'redux';

// Common
import UserReducers from './common/UserReducers';
import MeReducers from './common/MeReducers';
// feature
import RoleTypesReducer from './features/RoleTypesReducer';
import EmployeeReducer from './features/EmployeeReducer';
import ControlReducer from './features/ControlReducer';

const rootRuducer = combineReducers({
  me: MeReducers,
  user: UserReducers,
  roletype: RoleTypesReducer,
  employee: EmployeeReducer,
  control: ControlReducer,
});
export default rootRuducer;
