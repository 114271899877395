import { Box, Button, Card, Chip, IconButton, Slider } from '@mui/material';
import { ViewTitle } from 'components/ViewTitle';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import axios from 'axios';
import * as actions from '../../redux/actions';
import DriveControl1 from '../../assets/img/MCCNC1.png';
import DriveControl2 from '../../assets/img/MCCNC2.png';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { KeyboardArrowLeft } from '@mui/icons-material';

export default function DriveControl({ title, subtitle }) {
  const dispatch = useDispatch();
  const [alignment, setAlignment] = useState('nor');
  const [speedWheel, setSpeedWheel] = useState(2500);
  const [speedVertical, setSpeedVertical] = useState(2500);
  const [speedHorizontal, setSpeedHorizontal] = useState(2500);
  const [speedGripper, setSpeedGripper] = useState(2500);
  const [speedAxial, setSpeedAxial] = useState(2500);
  const [gripper, setGripper] = useState(true);
  const [wheelStop, setWheelStop] = useState(false);
  const [positionWheel, setPositionWheel] = useState(0);

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
    const data = {
      topic: 'sobot',
      payload: { topic: 'mode', payload: newAlignment },
    };
    dispatch(actions.forwardControl(data));
  };
  const handleChangeGriper = (event, newAlignment) => {
    setGripper(newAlignment);
    const data = {
      topic: 'sobot',
      payload: { topic: 'gripper', payload: newAlignment },
    };
    dispatch(actions.forwardControl(data));
  };
  const handleChangeSpeedGripper = (event, newValue) => {
    setSpeedGripper(newValue);
  };
  const handleChangeSpeedWheel = (event, newValue) => {
    setSpeedWheel(newValue);
  };
  const handleChangeSpeedVertical = (event, newValue) => {
    setSpeedVertical(newValue);
  };
  const handleChangeSpeedHorizontal = (event, newValue) => {
    setSpeedHorizontal(newValue);
  };
  const handleChangeSpeedAxial = (event, newValue) => {
    setSpeedAxial(newValue);
  };
  const handleCheckPuseWheel = (event, newValue) => {
    setSpeedAxial(newValue);
  };

  const handleCheckPositionWheel = (event, newValue) => {
    const data = {
      topic: 'sobot',
      payload: { topic: 'driveposition', payload: newValue },
    };
    dispatch(actions.forwardControl(data));
  };

  useEffect(() => {
    return () => {};
  }, []);

  const onControlForward = () => {
    const data = {
      topic: 'sobot',
      payload: { topic: 'wheeldrive', payload: 'forward' },
    };
    dispatch(actions.forwardControl(data));
    setWheelStop(true);
  };

  const onControlBackward = () => {
    const data = {
      topic: 'sobot',
      payload: { topic: 'wheeldrive', payload: 'backward' },
    };
    dispatch(actions.forwardControl(data));
    setWheelStop(true);
  };

  const onControlStop = () => {
    const data = {
      topic: 'sobot',
      payload: { topic: 'stopwheeldrive', payload: true },
    };
    dispatch(actions.forwardControl(data));
    setWheelStop(false);
  };

  const onControlHome = () => {
    // console.log('ffff');
    const data = {
      topic: 'sobot',
      payload: { topic: 'home', payload: 'home' },
    };
    dispatch(actions.forwardControl(data));
  };

  const handleCheckPositionHor = (event, newValue) => {
    const data = {
      topic: 'sobot',
      payload: { topic: 'hordriveposition', payload: newValue },
    };
    dispatch(actions.forwardControl(data));
  };

  const handleSetSpeed = (t, speed) => {
    const data = {
      topic: 'sobot',
      payload: { topic: t, payload: speed },
    };
    dispatch(actions.forwardControl(data));
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderButton = () => (
    <div className="lg:flex justify-center pb-4">
      <div className="flex  justify-center">
        <div>
          <div className="flex justify-center">
            <img src={DriveControl2} style={{ width: '60%', height: 'auto' }} />
          </div>
          {wheelStop ? (
            <div>
              <div className="flex justify-center">
                <div className="ml-4">
                  <IconButton>
                    <PauseCircleIcon
                      sx={{ fontSize: 40 }}
                      color="amber"
                      onClick={() => onControlStop()}
                    />
                  </IconButton>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex justify-center">
              <div className="ml-4">
                <IconButton>
                  <KeyboardArrowLeft
                    sx={{ fontSize: 40 }}
                    color="amber"
                    onClick={() => onControlBackward()}
                  />
                </IconButton>
              </div>

              <div className="mr-4">
                <IconButton>
                  <KeyboardArrowRightIcon
                    sx={{ fontSize: 40 }}
                    color="amber"
                    onClick={() => onControlForward()}
                  />
                </IconButton>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="w-full lg:flex">
        <div>
          <div className="flex justify-centers">
            <img src={DriveControl1} style={{ width: '90%', height: 'auto' }} />
          </div>

          <div className="flex justify-center">
            <Box sx={{ width: 250 }}>
              <Slider
                aria-label="Temperature"
                defaultValue={30}
                // getAriaValueText={valuetext}
                onChange={handleCheckPositionWheel}
                valueLabelDisplay="auto"
                shiftStep={30}
                step={10}
                marks
                min={0}
                max={100}
              />
            </Box>
          </div>
        </div>
        <div className="pt-6">
          <Box sx={{ height: 120 }}>
            <Slider
              aria-label="Temperature"
              defaultValue={30}
              onChange={handleCheckPositionHor}
              orientation="vertical"
              valueLabelDisplay="auto"
              shiftStep={30}
              step={10}
              marks
              min={0}
              max={100}
            />
          </Box>
        </div>
      </div>{' '}
    </div>
  );

  const renderSet = () => (
    <div className="w-full">
      <div>
        <div className="flex ">
          <h1 className=" font-bold">Speed Config</h1>
        </div>
        <div className=" w-full flex">
          <div className="w-full">Wheel Drive</div>
          <div className="w-full">
            <Slider
              defaultValue={speedWheel}
              disabled={alignment === 'jog' ? true : false}
              onChange={handleChangeSpeedWheel}
              valueLabelDisplay="auto"
              shiftStep={30}
              step={10}
              min={0}
              max={3000}
              style={{
                //   width: '31%',
                //   marginRight: '16px',
                //   marginLeft: '50px',
                color: '#FAC832',
              }}
            />
          </div>
          <div className="w-full grid grid-cols-2 ">
            <div className="pl-3">{(speedWheel / 1000)?.toFixed(2)} m/s </div>
            <div>
              <div className="px-1">
                <Button
                  disabled={alignment === 'jog' ? true : false}
                  size="small"
                  variant="contained"
                  onClick={() => handleSetSpeed('drivespeed', speedWheel)}
                >
                  set
                </Button>
              </div>
            </div>{' '}
          </div>
        </div>
      </div>
      <div>
        <div className=" w-full flex">
          <div className="w-full">Vertical Drive</div>
          <div className="w-full">
            <Slider
              aria-label="Temperature"
              defaultValue={speedVertical}
              disabled={alignment === 'jog' ? true : false}
              onChange={handleChangeSpeedVertical}
              valueLabelDisplay="auto"
              shiftStep={30}
              step={10}
              min={0}
              max={3000}
              style={{
                //   width: '31%',
                //   marginRight: '16px',
                //   marginLeft: '50px',
                color: '#FAC832',
              }}
            />
          </div>
          <div className="w-full grid grid-cols-2 ">
            <div className="pl-3">
              {(speedVertical / 1000)?.toFixed(2)} m/s{' '}
            </div>
            <div>
              <div className="px-1">
                <Button
                  disabled={alignment === 'jog' ? true : false}
                  size="small"
                  variant="contained"
                  onClick={() => handleSetSpeed('verdrivespeed', speedVertical)}
                >
                  set
                </Button>
              </div>
            </div>{' '}
          </div>
        </div>
      </div>
      <div>
        <div className=" w-full flex">
          <div className="w-full">Horizontal Drive</div>
          <div className="w-full">
            <Slider
              aria-label="Temperature"
              defaultValue={speedHorizontal}
              disabled={alignment === 'jog' ? true : false}
              onChange={handleChangeSpeedHorizontal}
              valueLabelDisplay="auto"
              shiftStep={30}
              step={10}
              min={0}
              max={3000}
              style={{
                color: '#FAC832',
              }}
            />
          </div>
          <div className="w-full grid grid-cols-2 ">
            <div className="pl-3">
              {(speedHorizontal / 1000)?.toFixed(2)} m/s{' '}
            </div>
            <div>
              <div className="px-1">
                <Button
                  disabled={alignment === 'jog' ? true : false}
                  size="small"
                  variant="contained"
                  onClick={() =>
                    handleSetSpeed('hordrivespeed', speedHorizontal)
                  }
                >
                  set
                </Button>
              </div>
            </div>{' '}
          </div>
        </div>
      </div>
      <div>
        <div className=" w-full flex">
          <div className="w-full">Axial Drive</div>
          <div className="w-full">
            <Slider
              aria-label="axial Drive"
              defaultValue={speedAxial}
              // getAriaValueText={valuetext}
              disabled={alignment === 'jog' ? true : false}
              onChange={handleChangeSpeedAxial}
              valueLabelDisplay="auto"
              shiftStep={30}
              step={10}
              min={0}
              max={3000}
              style={{
                //   width: '31%',
                //   marginRight: '16px',
                //   marginLeft: '50px',
                color: '#FAC832',
              }}
            />
          </div>
          <div className="w-full grid grid-cols-2 ">
            <div className="pl-3">{(speedAxial / 1000)?.toFixed(2)} m/s </div>
            <div>
              <div className="px-1">
                <Button
                  disabled={alignment === 'jog' ? true : false}
                  size="small"
                  variant="contained"
                  onClick={() => handleSetSpeed('axdrivespeed', speedAxial)}
                >
                  set
                </Button>
              </div>
            </div>{' '}
          </div>
        </div>
      </div>
      <div>
        <div className=" w-full flex">
          <div className="w-full">Gripper Drive</div>
          <div className="w-full">
            <Slider
              defaultValue={speedGripper}
              // getAriaValueText={valuetext}
              disabled={alignment === 'jog' ? true : false}
              onChange={handleChangeSpeedGripper}
              valueLabelDisplay="auto"
              shiftStep={30}
              step={10}
              min={0}
              max={3000}
              style={{
                //   width: '31%',
                //   marginRight: '16px',
                //   marginLeft: '50px',
                color: '#FAC832',
              }}
            />
          </div>
          <div className="w-full grid grid-cols-2 ">
            <div className="pl-3">{(speedGripper / 1000)?.toFixed(2)} m/s </div>
            <div>
              <div className="px-1">
                <Button
                  disabled={alignment === 'jog' ? true : false}
                  size="small"
                  variant="contained"
                  onClick={() => handleSetSpeed('gripspeed', speedGripper)}
                >
                  set
                </Button>
              </div>
            </div>{' '}
          </div>
        </div>
      </div>
    </div>
  );

  const renderButtonStyleSpeed = () => (
    <div>
      <h1 className="font-bold">Mode</h1>
      <div>
        <ToggleButtonGroup
          color="error"
          value={alignment}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
        >
          <ToggleButton value="nor">Normal</ToggleButton>
          <ToggleButton value="jog">joggle</ToggleButton>
          <ToggleButton value="po">Position</ToggleButton>
        </ToggleButtonGroup>
      </div>
    </div>
  );

  const renderButtonhome = () => (
    <div className="py-1">
      <h1 className="font-bold">Home</h1>
      <div>
        <Button variant="outlined" onClick={() => onControlHome()}>
          HOME
        </Button>
      </div>
    </div>
  );

  const renderButtonGripper = () => (
    <div className="py-2">
      <h1 className="font-bold">Gripper</h1>
      <div>
        <ToggleButtonGroup
          color="primary"
          value={gripper}
          exclusive
          onChange={handleChangeGriper}
          aria-label="Platform"
        >
          <ToggleButton value={true}>Pick</ToggleButton>
          <ToggleButton value={false}>place</ToggleButton>
        </ToggleButtonGroup>
      </div>
    </div>
  );

  const renderStatus = () => (
    <div>
      <Card>
        <div className="px-2 py-2 flex justify-between ">
          <div>
            <div>
              Status : <Chip label="Ready" color="success" size="small" />
            </div>
            <div> Speed M1 : 1500 </div>
            <div> Speed M2 : 1500 </div>
          </div>
          <div className="py-4 flex gap-2 ">
            <div>
              <Button variant="contained" onClick={() => onControlHome()}>
                home
              </Button>
            </div>
            <div>
              <Button
                variant="contained"
                color="error"
                onClick={() => onControlStop()}
              >
                STOP
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );

  return (
    <div>
      {renderTitle()}
      {/* <div className="py-2">{renderStatus()}</div> */}
      <div>{renderButton()}</div>
      <div className="lg:flex">
        <div className="lg:w-1/2  ">{renderSet()}</div>
        <div>
          {renderButtonhome()}
          {renderButtonStyleSpeed()}
          {renderButtonGripper()}
        </div>
      </div>
    </div>
  );
}
