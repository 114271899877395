import axios from 'axios';
import * as actions from '../../actions';
import { CONTROL_LOADING, CONTROL_PUB, CONTROL_ERROR } from '../types';

import api from '../../../utils/functions/api';

// export const forwardControl = (payload) => {
//   return async (dispatch) => {
//     await axios
//       .post(process.env.REACT_APP_API_URL + 'control/timer/' + payload)
//       .then(async (res) => {
//         console.log('Request Timer ON');

//         // await axios.get(
//         //   process.env.REACT_APP_API_URL + '/station/' + stationRef
//         // )
//         if (res.status === 200) {
//           dispatch(actions.stationAll());
//         }
//       });
//   };
// };

export const forwardControl = (payload) => async (dispatch) => {
  try {
    dispatch({ type: CONTROL_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/control`,
      payload,
    );
    dispatch({ type: CONTROL_PUB, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: CONTROL_ERROR });
    throw new Error(error);
  }
};
