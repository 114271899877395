import { ViewTitle } from 'components/ViewTitle';
import React from 'react';
import imageSobot from '../../assets/img/MicrogreenDashboard.png';
import { Card } from '@mui/material';

export default function Dashboard({ title, subtitle }) {
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderDashBoard = () => (
    <div>
      {' '}
      {/* <Card> */}
      <div className="flex justify-center">
        <div>
          <div className="flex items-center">
            <div className="ml-4"></div>
            <div>
              <div>
                <img
                  src={imageSobot}
                  style={{ width: '100%', height: 'auto' }}
                />
              </div>
            </div>
            <div className="mr-4"></div>
          </div>
        </div>
      </div>{' '}
      {/* </Card> */}
    </div>
  );

  return (
    <div>
      {renderTitle()}
      <div>{renderDashBoard()}</div>
    </div>
  );
}
