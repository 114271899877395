import { ViewTitle } from 'components/ViewTitle';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../redux/actions';
import { Card } from '@mui/material';

export default function PersonalProfile({ title, subtitle }) {
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  useEffect(() => {
    dispatch(actions.meGet());

    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderDetail = () => {
    return (
      <div className="w-full">
        <Card>
          <div className="flex flex-wrap p-4">
            <div className="w-full">
              <div className="flex justify-center">
                {/* <img src={image?.url} className="w-60 rounded-lg" /> */}
              </div>
            </div>

            <div className="w-full text-center">
              <div className="text-2xl font-semibold font-display text-blue-800 py-2 mt-2"></div>
              <div className="py-2">เบอร์โทรศัพท์ : </div>
              <div className="py-2">แผนก : </div>
              {/* {!_.isEmpty(me?.userData?.other_departments) &&
                _.map(me?.userData?.other_departments, (_otherDept, index) => (
                  <div key={index}>{_otherDept?.name} </div>
                ))}
              {me?.userData?.driver_line?.name && (
                <div className="py-2">
                  เส้นทาง : {me?.userData?.driver_line?.name}
                </div>
              )} */}
            </div>
          </div>
        </Card>
      </div>
    );
  };

  return (
    <div>
      {renderTitle()}
      {renderDetail()}
    </div>
  );
}
