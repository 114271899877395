import { Button, Card, Chip, IconButton } from '@mui/material';
import { ViewTitle } from 'components/ViewTitle';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import axios from 'axios';
import * as actions from '../../redux/actions';
import DriveControl1 from '../../assets/img/DriveControl1.png';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export default function DriveControl({ title, subtitle }) {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {};
  }, []);
  const onControlForward = () => {
    // console.log('ffff');
    const data = {
      topic: 'drive',
      payload: 'forward',
    };
    dispatch(actions.forwardControl(data));
  };

  const onControlStop = () => {
    // console.log('ffff');
    const data = {
      topic: 'drive',
      payload: 'stop',
    };
    dispatch(actions.forwardControl(data));
  };

  const onControlBackward = () => {
    // console.log('ffff');
    const data = {
      topic: 'drive',
      payload: 'backward',
    };
    dispatch(actions.forwardControl(data));
  };

  const onControlHome = () => {
    // console.log('ffff');
    const data = {
      topic: 'home',
      payload: 'backward',
    };
    dispatch(actions.forwardControl(data));
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderButton = () => (
    <Card>
      <div className="flex justify-center">
        <div>
          <div className="flex">
            <div className="py-20">
              <IconButton>
                <ArrowBackIosIcon
                  sx={{ fontSize: 40 }}
                  color="amber"
                  onClick={() => onControlForward()}
                />
              </IconButton>
            </div>
            <div>
              <div>
                <img src={DriveControl1} width="300" height="100" />
              </div>
            </div>
            <div className="py-20">
              <IconButton>
                <ArrowForwardIosIcon
                  sx={{ fontSize: 40 }}
                  color="amber"
                  onClick={() => onControlBackward()}
                />
              </IconButton>
            </div>
          </div>
        </div>
      </div>{' '}
    </Card>
  );

  const renderStatus = () => (
    <div>
      <Card>
        <div className="px-2 py-2 flex justify-between ">
          <div>
            <div>
              Status : <Chip label="Ready" color="success" size="small" />
            </div>
            <div> Speed M1 : 1500 </div>
            <div> Speed M2 : 1500 </div>
          </div>
          <div className="py-4 flex gap-2 ">
            <div>
              <Button variant="contained" onClick={() => onControlHome()}>
                home
              </Button>
            </div>
            <div>
              <Button
                variant="contained"
                color="error"
                onClick={() => onControlStop()}
              >
                STOP
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );

  return (
    <div>
      {renderTitle()}
      <div className="py-2">{renderStatus()}</div>
      <div>{renderButton()}</div>
    </div>
  );
}
